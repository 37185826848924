import { Breadcrumbs, Link, Stack, Typography } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { SelectOptions } from './menu-form';

interface ModuleProps {
  path: SelectOptions[];
  handleClickModulePath: (moduleIndex: number) => void;
}

/**
 * Exibe o nível do menu selecionado
 *
 * @author Marcos Davi <marcos.davi@bubo.tech>
 */
function CurrenPath({ path, handleClickModulePath }: ModuleProps): JSX.Element {
  const levels = path.slice(0, -1);
  const current = path[path.length - 1];

  return (
    <Stack style={{ margin: '10px 0' }}>
      <Breadcrumbs separator={<ArrowForwardIos style={{ height: 15 }} />}>
        {levels.map((level, moduleIndex) => (
          <Link onClick={() => handleClickModulePath(moduleIndex)} style={{ cursor: 'pointer' }}>{level.label}</Link>
        ))}
        <Typography>{current.label}</Typography>
      </Breadcrumbs>
    </Stack>
  );
}

export default CurrenPath;
