import React, { Fragment, useState } from 'react';

import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import MenuForm, { SelectOptions } from './views/menu-form';
import MenuItemsTable from './views/menu-items-table';
import MenuItemResourcesForm from './views/resources-form';

import { Swal } from 'root-components/swal';
import FormCrud from '@templates/FormCrud';
import { ButtonFABMenu } from '@bubotech/sumora-react-components';

import MenuItemSchema, { MenuItemProps } from '../models/menu-item';
import RouteResource from '../models/resource-menu-item';
import ConfigureMenuController from '../controller/config-menu.controller';

const controller = new ConfigureMenuController();

/**
 * Página de configuração do menu lateral
 * @author Marcos Davi <marcos.davi@bubo.tech>
 */
function ConfigMenu(): JSX.Element {
  const navigate = useNavigate();

  const [formKey, setFormKey] = useState(0);
  const [routeResourceEditValue, setRouteResourceEditValue] = useState<RouteResource>();

  const itemPath = useState<SelectOptions[]>([]);

  const form = useFormik<MenuItemProps>({
    initialValues: MenuItemSchema.getDefault(),
    onSubmit: (values) => controller.handleSaveModule(values, itemPath[0], handleSuccess),
    validationSchema: MenuItemSchema,
    validateOnBlur: true,
  });

  function handleSuccess() {
    Swal.Success('Registrado com sucesso');

    form.resetForm();
    setFormKey(Math.random());

    const setPath = itemPath[1];
    setPath([]);

    controller.resetMap();
  }

  function handleAddAction(action: RouteResource) {
    const isEdit = routeResourceEditValue !== undefined;

    if (isEdit) editAction(action);
    else form.setFieldValue('resourceList', form.values.resourceList?.concat(action));

    setRouteResourceEditValue(undefined);
  }

  function editAction(newValue: RouteResource) {
    const foundItemIndex = form.values.resourceList?.findIndex((item) => item.tpMethod === routeResourceEditValue?.tpMethod);
    
    if (foundItemIndex && foundItemIndex !== -1) {
      const list = [...(form.values.resourceList || [])];

      list[foundItemIndex] = newValue;
      form.setFieldValue('resourceList', list);
    }
  }

  return (
    <Fragment>
      <FormCrud title='Configuração Menu'>
        <MenuForm itemPath={itemPath} controller={controller} formKey={formKey} form={form} />

        <MenuItemResourcesForm editValue={routeResourceEditValue} addAction={handleAddAction} />
      </FormCrud>
      <MenuItemsTable selectToEdit={setRouteResourceEditValue} data={form.values.resourceList || []} />
      <ButtonFABMenu
        primaryAction={{ onClick: () => form.handleSubmit(), iconProps: { color: 'inherit' } }}
        secondaryAction={{
          onClick: () => navigate(-1),
          iconProps: { color: 'inherit' },
        }}
      />
    </Fragment>
  );
}

export default ConfigMenu;
