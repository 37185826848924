import { Select, TextField } from '@bubotech/sumora-react-components';
import { Grid, IconButton } from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import { useStyles } from 'root-views/app.styles';
import { useFormik } from 'formik';
import RouteResource, { RouteResourceSchema } from '../../models/resource-menu-item';
import { TipoMetodoResource } from '../../enums/tipo-metodo';
import { Add } from '@mui/icons-material';

type ResourceFormType = {
  editValue?: RouteResource;
  addAction: (action: RouteResource) => void;
};

function MenuItemResourcesForm({ editValue, addAction }: ResourceFormType): JSX.Element {
  const classes = useStyles();

  const form = useFormik<RouteResource>({
    validationSchema: RouteResourceSchema,
    initialValues: RouteResourceSchema.getDefault(),
    onSubmit: (value) => {
      addAction(value);
      form.resetForm();
    },
  });

  function getFieldProps(field: keyof RouteResource) {
    const hasError = Boolean(form.errors[field] && form.touched[field]);

    return {
      name: field,
      value: form.values[field],
      error: hasError,
      helperText: hasError ? form.errors[field] : '',
      onBlur: form.handleBlur,
    };
  }

  useEffect(() => {
    if (editValue) form.setValues(editValue);
  }, [editValue]);

  return (
    <Fragment>
      <Grid xs={12} className={classes.container} style={{ marginBottom: 10 }}>
        Ações
      </Grid>
      <Grid item xs={3} className={classes.gridCell}>
        <Select
          getOptionLabel={(opt) => opt.label}
          getOptionValue={(opt) => opt.value}
          label='Tipo'
          placeholder='Selecione...'
          options={availableActions}
          // disabled={editValue !== undefined}
          onChangeValue={(e) => form.setFieldValue('tpMethod', e.value)}
          {...getFieldProps('tpMethod')}
        />
      </Grid>
      <Grid item xs={3} className={classes.gridCell}>
        <TextField
          disabled={form.values.tpMethod === undefined}
          label='Endpoint'
          {...getFieldProps('dsPath')}
          onChange={(e) => form.setFieldValue(e.target.name, e.target.value)}
        />
      </Grid>
      <Grid item xs={3} className={classes.gridCell}>
        <TextField
          disabled={form.values.tpMethod === undefined}
          label='Descrição'
          {...getFieldProps('dsResource')}
          inputProps={{
            maxLength: 20,
          }}
          onChange={(e) => form.setFieldValue(e.target.name, e.target.value)}
        />
      </Grid>
      <Grid item className={classes.switchContainer}>
        <IconButton onClick={() => form.handleSubmit()}>
          <Add color='primary' />
        </IconButton>
      </Grid>
    </Fragment>
  );
}

const availableActions = [
  { label: 'Buscar', value: TipoMetodoResource.GET },
  { label: 'Editar', value: TipoMetodoResource.PUT },
  { label: 'Adicionar', value: TipoMetodoResource.POST },
  { label: 'Remover', value: TipoMetodoResource.DELETE },
]

export default MenuItemResourcesForm;
