import { makeStyles } from "@mui/styles";

const useCrudStyles = makeStyles((theme: any) => ({
  titleContainer: {
    maxHeight: 44,
    padding: '12px 25px',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    fontSize: 18,
    color: '#000000',
    fontWeight: 400,
    width: '100%',
  },
}));

export default useCrudStyles;