import React, { Fragment, useState } from 'react';
import { useTheme } from '@mui/material';

import { useStyles } from 'root-views/app.styles';

import { DataTable, GroupButtonGrid } from '@bubotech/sumora-react-components';
import RouteResource from '../../models/resource-menu-item';

interface MenuItemsTableProps {
  data: RouteResource[];
  selectToEdit: (value: RouteResource) => void;
}

function MenuItemsTable({ data, selectToEdit }: MenuItemsTableProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  const [selectedItem, setSelectedItem] = useState<RouteResource>();

  const defaultProps = {
    disabled: !selectedItem,
    disabledColor: theme.palette.grey['100'],
  };

  function handleClickEdit() {
    if (!selectedItem) return;

    selectToEdit(selectedItem);
  }

  return (
    <Fragment>
      <GroupButtonGrid
        onClickEdit={handleClickEdit}
        buttonEditProps={{ backgroundColor: theme.palette?.primary.main, ...defaultProps }}
        buttonDeleteProps={defaultProps}
        showDelete={false}
        showEdit={false}
        showAdd={false}
      />
      <div className={`${classes.containerDataTable} tabela sem-bordas`} style={{ height: 'calc(100vh - 635px)' }}>
        <DataTable
          data={data}
          columns={[
            {
              col: 2,
              field: 'tpMethod',
              headerName: 'Tipo',
              valueFormatter: (node) => ['Listar', 'Salvar', 'Edição parcial', 'Editar', 'Remover'][node.data.tpMethod],
            },
            {
              col: 4,
              field: 'dsPath',
              headerName: 'Endpoint',
            },
            {
              col: 6,
              field: 'dsResource',
              headerName: 'Descrição',
            },
          ]}
          onSelectRow={setSelectedItem}
          rowsPerPageEnabled={false}
        />
      </div>
    </Fragment>
  );
}

export default MenuItemsTable;
