import Swal from './swal';

interface ValidacaoErro {
  campo: string;
  mensagem: string;
}

export interface MensagemErro {
  titulo: string;
  mensagem: string;
  nome_exception: string;
  error: boolean;
  validacoes?: ValidacaoErro[];
  nmEmpresa?: string;
  nrCnpj?: string;
}

function ErrorSwal(err: any, errorMsg?: string, errorTitle?: string) {
  let error: MensagemErro = { ...err?.response?.data, error: true };

  Swal({
    showConfirmButton: false,
    showCancelButton: true,
    cancelButtonText: 'Ok',
    title: error.titulo || errorTitle || 'Ocorreu um erro',
    text: error.mensagem || errorMsg || 'Falha ao carregar os dados',
    icon: 'error',
  });
}

export default ErrorSwal;
