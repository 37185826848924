import iconDasboard from '../../assets/icons/icon-dashboard.svg';
import iconConfigAccess from '../../assets/icons/icon-config-access.svg';
import { firtsLevelMenuColor } from 'root-utils/constants';

/**
 * Modelo das definições de um item do menu lateral
 *
 * @author Marcos Davi <marcos.davis@bubotech.com.br>
 */
export interface MenuLateralItemModel {
  label: string | JSX.Element;
  Icon?: (props: any) => JSX.Element;
  route?: string;
  childrens?: MenuLateralItemModel[];
  childrensBackgroundColor?: string;
}

/**
 * Enum de cores de fundo dos itens do menu pra cada nível.
 * O primeiro nível são os filhos do primeiro item.
 */
const LevelColors = {
  FIRST_LEVEL: firtsLevelMenuColor,
  SECOND_LEVEL: '#5C78C7',
  THIRD_LEVEL: '#8DA5E8',
};

/**
 * Lista de definições dos itens do menu lateral
 */
const itensMenuLateral: MenuLateralItemModel[] = [
  {
    label: 'Dashboard',
    Icon: (props) => <img src={iconDasboard} alt='ícone-dashboard' {...props} />,
    route: '/dashboard',
  },
  {
    label: 'Configurações de Acesso',
    Icon: (props) => <img src={iconConfigAccess} alt='ícone-configurações-acesso' {...props} />,
    childrensBackgroundColor: LevelColors.FIRST_LEVEL,
    childrens: [
      { label: 'Usuário', route: '/cadastros/usuario' },
      { label: 'Empresa', route: '/cadastros/empresa' },
      { label: 'Grupo de usuários', route: '/cadastros/grupo-usuario' },
      { label: 'Grupo econômico', route: '/cadastros/grupo-economico' },
      { label: 'Configuração', route: '/cadastros/configuracao' },
      { label: 'Configuração Firebase', route: '/cadastros/firebase-configuracao' },
      {
        label: 'Menu',
        route: '/cadastros/desenvolvimento/menu',
      },
    ],
  },
];

export default itensMenuLateral;
