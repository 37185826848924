import ErrorSwal from "./error";
import SuccessSwal from "./confirm";
import LoadingSwal from "../loadingswal/loading-swal";

const Loading = () => LoadingSwal({ text: 'Carregando' });

export const Swal = {
  Error: ErrorSwal,
  Success: SuccessSwal,
  Loading: Loading
}