import * as Yup from 'yup';
import RouteResource, { RouteResourceSchema } from './resource-menu-item';

const MenuItemSchema = Yup.object().shape({
  idMenu: Yup.string().optional().nullable().default(null),
  hashKey: Yup.string().optional().default(''),
  nmMenu: Yup.string().required('Campo obrigatório').default(''),
  dsIcone: Yup.string().default(''),
  dsSource: Yup.string().required('Campo obrigatório').default(''),
  nrOrdem: Yup.number().required('Campo obrigatório').default(0),
  // Array de MenuItemSchema
  menuList: Yup.array().optional().default([]),
  resourceList: Yup.array().of(RouteResourceSchema).default([]).min(0, 'Necessário adicionar ao mesmo método.'),
  stRegistro: Yup.number().default(0)
});


export type MenuItemHash = string;

export type MenuRecord = Record<MenuItemHash, MenuItemProps>;

export type MenuItemProps = {
  idMenu: string | null;
  hashKey?: string;
  nmMenu: string;
  dsIcone?: string;
  dsSource: string;
  nrOrdem?: number;
  menuList?: MenuItemProps[];
  resourceList?: RouteResource[];
  stRegistro: number;
  data?: MenuItemProps[];
};

export default MenuItemSchema;