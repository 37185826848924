import { makeStyles } from "@mui/styles";

const useFormTabStyles = makeStyles((theme: any) => ({
  tabContent: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: '25px 17px',
    flex: 1,
    overflow: 'hidden',
  },
}));

export default useFormTabStyles;