import { Grid } from '@mui/material';
import { PropsWithChildren } from 'react';
import useFormTabStyles from './formtab.styles';

interface FormTabProps {}

/**
 * Template para uma aba de formulário
 * @author Marcos Davi <marcos.davi@bubo.tech>
 */
function FormTab({ children }: PropsWithChildren<FormTabProps>): JSX.Element {
  const classes = useFormTabStyles();

  return (
    <Grid container className={classes.tabContent}>
      {children}
    </Grid>
  );
}

export default FormTab;
