import React, { PropsWithChildren } from 'react';

import FormTab from '../FormTab';

import useCrudStyles from './crud.styles';
import useAppLayout from '@hooks/use-layout';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';

interface FormCrudProps {
  title: string;
}

/**
 * Template para um formulário simples página única
 * @author Marcos Davi <marcos.davi@bubo.tech>
 */
function FormCrud({ title, children }: PropsWithChildren<FormCrudProps>): JSX.Element {
  const classes = useCrudStyles();
  const layout = useAppLayout();

  useComponentDidMount(setLayoutTitle);

  function setLayoutTitle() {
    layout.actions.setTitleToolbar(title);
  }

  return (
    <section id={'form'} style={{ display: 'grid' }}>
      <span className={classes.titleContainer}>{title}</span>
      <FormTab>{children}</FormTab>
    </section>
  );
}

export default FormCrud;
