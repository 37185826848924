import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Dispatch } from 'redux';
import { MainStateType } from 'root-states';
import Swal from 'root-components/swal/swal';
import { useNavigate, useParams, Params } from 'react-router-dom';
import { FormikHelpers, useFormik } from 'formik';
import { Person, ManageAccounts, BusinessCenter } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import Usuario from 'root-models/usuario';
import LoadingSwal from 'root-components/loadingswal/loading-swal';
import { ButtonFABMenu } from '@bubotech/sumora-react-components/lib';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import VerticalTabs from '@bubotech/sumora-react-components/lib/verticaltabs';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import UsuarioAPI from 'root-resources/api/usuario';

import { v4 as uuidv4 } from 'uuid';
import EditarDados from './editar-dados';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import GrupoUsuarios from './grupo-usuario';
import EmpresaModel from 'root-models/empresa';
import Empresas from './empresa';
import GrupoUsuario from 'root-models/grupo-usuario';

/**
 * Tipo dos valores do formik
 */
export interface EditarUsuarioFormikValuesType extends Usuario {}

export type EditarUsuarioPropType = {};

/**
 * View de edição de usuário
 *
 * @author Marcos Davi <marcos.davi@bubotech.com.br>
 * @param {EditarUsuarioPropType} props
 */
function EditarUsuario(props: EditarUsuarioPropType): JSX.Element {
  const history = useNavigate();
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const isLoading = useSelector<MainStateType, boolean>(state => state.appLayoutReducer.mainLoading);
  const { id }: Readonly<Params<string>> = useParams();
  const api = new UsuarioAPI();

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [grupoEmpresaList, setGrupoEmpresaList] = useState<EmpresaModel[]>([]);
  const [grupoEmpresaListToDelete, setGrupoEmpresaListToDelete] = useState<EmpresaModel[]>([]);
  const [grupoUsuarioList, setGrupoUsuarioList] = useState<GrupoUsuario[]>([]);
  const [grupoUsuarioListToDelete, setGrupoUsuarioListToDelete] = useState<GrupoUsuario[]>([]);
  const [initialValues, setInitialValues] = useState<EditarUsuarioFormikValuesType>({
    nmUsuario: '',
    dsEmail: '',
    nrTelefone: '',
    stUsuario: SituacaoEnum.ATIVO
  });

  const { values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue } =
    useFormik<Usuario>({
      validateOnBlur: true,
      validateOnChange: false,
      enableReinitialize,
      initialValues,
      validationSchema: Yup.object().shape({
        nmUsuario: Yup.string().required('Campo obrigatório'),
        dsEmail: Yup.string().email('Email inválido').required('Campo obrigatório'),
        nrTelefone: Yup.string().nullable().required('Campo obrigatório')
      }),
      onSubmit: handleSubmitFormik
    });

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Usuário');

    if (id) {
      setEnableReinitialize(true);
    }
  });

  useEffect(() => {
    if (!enableReinitialize) return;
    appLayoutActions.setLoading(true);

    api
      .findById(id)
      .then(res => {
        setInitialValues(res.data);
        setGrupoEmpresaList(res.data.empresaList || []);
        setGrupoUsuarioList(res.data.grupoList || []);
        setEnableReinitialize(false);
        appLayoutActions.setLoading(false);
      })
      .catch(() => {
        appLayoutActions.setLoading(false);

        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: 'Ocorreu um erro',
          text: 'Falha ao carregar os dados',
          icon: 'error'
        });
      });

    // eslint-disable-next-line
  }, [enableReinitialize]);

  return (
    <main style={{ height: '100%' }}>
      <VerticalTabs
        title={id ? 'Edição de Usuário' : 'Cadastro de Usuário'}
        menuEffect
        selectedIndex={selectedIndex}
        onChangeIndex={tab => setSelectedIndex(tab)}
        tabs={[
          {
            content: (
              <EditarDados
                onChange={handleChange}
                setFieldValue={setFieldValue}
                values={values}
                touched={touched}
                errors={errors}
                handleBlur={handleBlur}
              />
            ),
            label: 'Dados do Usuário',
            icon: <Person />
          },
          {
            content: (
              <Empresas
                empresaSelectedList={grupoEmpresaList}
                addEmpresa={addEmpresaInGrupo}
                deleteEmpresa={deletarEmpresaInGrupo}
              />
            ),
            label: 'Empresa',
            icon: <BusinessCenter />
          },
          {
            content: (
              <GrupoUsuarios
                empresaList={grupoEmpresaList}
                grupoUsuarioSelectedList={grupoUsuarioList}
                addGrupoUsuario={addUsuarioInGrupo}
                deletegrupoUsuario={deletarUsuarioInGrupo}
              />
            ),
            label: 'Grupo de Usuário',
            icon: <ManageAccounts />
          }
        ]}
      />

      <ButtonFABMenu
        disabled={isLoading}
        primaryAction={{
          onClick: (e: any) => handleSubmit(e),
          iconProps: { color: 'inherit' }
        }}
        secondaryAction={{
          onClick: () => history('/cadastros/usuario'),
          iconProps: { color: 'inherit' }
        }}
      />
    </main>
  );

  /**
   * Adicionar grupo na lista
   * @param values
   */
  function deletarEmpresaInGrupo(values: EmpresaModel) {
    const grupoEmpresaListAux = [...grupoEmpresaList];
    const itemToDelete = grupoEmpresaListAux.find(toDelete => toDelete.idEmpresa === values?.idEmpresa);
    if (itemToDelete) {
      const position = grupoEmpresaListAux.indexOf(itemToDelete);
      grupoEmpresaListAux.splice(position, 1);
      setGrupoEmpresaList(grupoEmpresaListAux);

      if (itemToDelete.stRegistro === 1) {
        itemToDelete.stRegistro = 2;
        const grupoEmpresaListToDeleteAux = [...grupoEmpresaListToDelete];
        grupoEmpresaListToDeleteAux.push(itemToDelete);
        setGrupoEmpresaListToDelete(grupoEmpresaListToDeleteAux);
      }
    }
  }

  /**
   * Adicionar grupo na lista
   * @param values
   */
  function addEmpresaInGrupo(values: EmpresaModel) {
    const grupoEmpresaListAux = [...grupoEmpresaList];

    grupoEmpresaListAux.push({
      ...values,
      stRegistro: 0
    });

    setGrupoEmpresaList(grupoEmpresaListAux);
  }

  /**
   * Adicionar grupo na lista
   * @param values
   */
  function deletarUsuarioInGrupo(values: GrupoUsuario) {
    const grupoUsuarioListAux = [...grupoUsuarioList];
    const itemToDelete = grupoUsuarioListAux.find(toDelete => toDelete.idGrupo === values?.idGrupo);
    if (itemToDelete) {
      const position = grupoUsuarioListAux.indexOf(itemToDelete);
      grupoUsuarioListAux.splice(position, 1);
      setGrupoUsuarioList(grupoUsuarioListAux);

      if (itemToDelete.stRegistro === 1) {
        itemToDelete.stRegistro = 2;
        const grupoUsuarioListToDeleteAux = [...grupoUsuarioListToDelete];
        grupoUsuarioListToDeleteAux.push(itemToDelete);
        setGrupoUsuarioListToDelete(grupoUsuarioListToDeleteAux);
      }
    }
  }

  /**
   * Adicionar grupo na lista
   * @param values
   */
  function addUsuarioInGrupo(values: GrupoUsuario) {
    const grupoUsuarioListAux = [...grupoUsuarioList];

    grupoUsuarioListAux.push({
      ...values,
      stRegistro: 0
    });

    setGrupoUsuarioList(grupoUsuarioListAux);
  }

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {EditarUsuarioFormikValuesType} values - Valores do submit
   * @param {FormikHelpers<EditarUsuarioFormikValuesType>} formikHelpers - Auxiliares
   */
  async function handleSubmitFormik(
    values: EditarUsuarioFormikValuesType,
    formikHelpers: FormikHelpers<EditarUsuarioFormikValuesType>
  ) {
    LoadingSwal({ text: 'Carregando' });
    let error = false;

    values.grupoList = grupoUsuarioList || [];
    grupoUsuarioListToDelete.forEach(grupo => {
      values.grupoList?.push(grupo);
    });

    values.empresaList = grupoEmpresaList || [];
    grupoEmpresaListToDelete.forEach(grupoEmpresa => {
      values.empresaList?.push(grupoEmpresa);
    });

    values.nrTelefone = values.nrTelefone?.replaceAll(' ', '');

    if (!id) {
      values.idUsuario = uuidv4();
      await api.save(values).catch(e => {
        error = true;
      });
    } else {
      values.idUsuario = id;
      await api.update(values).catch(e => {
        error = true;
      });
    }

    if (!error) {
      Swal({
        showConfirmButton: true,
        title: 'Sucesso',
        text: id ? 'Editado com sucesso' : 'Cadastro com sucesso',
        icon: 'success'
      });

      history('/cadastros/usuario');
    } else {
      Swal({
        showConfirmButton: true,
        title: 'Erro',
        text: id ? 'Erro ao editar' : 'Erro ao cadastrar',
        icon: 'error'
      });
    }
  }
}

export default EditarUsuario;
