import * as Yup from 'yup';
import { TipoMetodoResource } from '../enums/tipo-metodo';
import SituacaoEnum from 'root-enumerations/situacao-enum';

export const RouteResourceSchema = Yup.object({
  idResource: Yup.string().nullable().default(null),
  dsResource: Yup.string().required('Campo obrigatório').nullable().default(''),
  dsPath: Yup.string().required('Campo obrigatório').default('').test(
    'validate-endpoint',
    'O endpoint não pode conter espaços',
    (value) => Boolean(value && !/\s/.test(value)) 
  ),
  tpMethod: Yup.number().required('Campo obrigatório').default(undefined),
  stResource: Yup.number().oneOf([0, 1]).default(0),
  acaoList: Yup.array().default([]),
  dsConstante: Yup.string().default(''),
})

export default interface RouteResource {
  idResource: string | null;
  dsResource: string | null;
  dsPath: string;
  dsConstante: string;
  tpMethod?: TipoMetodoResource;
  stResource: SituacaoEnum;
}