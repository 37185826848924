import MenuItemSchema, { MenuItemProps, MenuRecord } from '../models/menu-item';

import { Swal } from 'root-components/swal';
import ConfigMenuAPI from '../resources/api';
import { SelectOptions } from '../pages/views/menu-form';

const api = new ConfigMenuAPI();

class ConfigureMenuController {
  private menuMap: MenuRecord;
  private menuItemsData: MenuItemProps[];

  constructor() {
    this.menuMap = {};
    this.menuItemsData = [];
  }

  public async findRootMenuData() {
    try {
      const res = await api.findRootMenuItems();

      this.menuItemsData = res.data.data.map((item) => this.createMenuItem(item));
    } catch (err) {
      Swal.Error(err);
    }

    return this.menuItemsData;
  }

  public async fetchMenuItemChildren(moduleId: string) {
    const moduleIsOnMemory = this.moduleIsOnMemory(moduleId);

    if (!moduleIsOnMemory)
      await api
        .findModuleChildren(moduleId)
        .then((res) => this.insertNewItems(res.data || []))
        .catch(Swal.Error);


    if (!this.menuMap[moduleId]) 
      this.menuMap[moduleId] = MenuItemSchema.getDefault();

    return this.menuItemsData;
  }

  public async handleSaveModule(values: MenuItemProps, currentPath: SelectOptions[], onSuccess: () => void) {
    const isSubmodule = currentPath.length;
    let body = { ...values };

    const isEdit = body.idMenu !== null;
    const pathToModule = !isEdit ? currentPath[currentPath.length - 1] : currentPath[currentPath.length - 2];

    Swal.Loading();
    
    if (isSubmodule) body = this.formatValuesToInsert(pathToModule, values);

    const request = isEdit || isSubmodule ? api.update(body) : api.save(body);
    await request.then(onSuccess).catch(Swal.Error);
  }

  public getMenuItems() {
    return this.menuItemsData;
  }

  public getModule(id: string) {
    return this.menuMap[id];
  }

  public resetMap() {
    this.menuMap = {};
  }

  private createMenuItem(item: MenuItemProps, menuList?: MenuItemProps[]) {
    let data: MenuItemProps[] = [];

    if (menuList?.length) {
      data = menuList.map((item) => ({
        ...item,
        data: [],
      }));
    }

    return {
      ...item,
      data,
      menuList: undefined,
    };
  }

  private moduleIsOnMemory(moduleId: string) {
    return Object.keys(this.menuMap).includes(moduleId);
  }

  private insertNewItems(module: MenuItemProps) {
    this.menuMap[module!.idMenu!] = module;

    this.menuItemsData = this.insertNewItemsOnData(this.menuItemsData, module.idMenu || '', module.menuList || []);
  }

  private insertNewItemsOnData(menuItemsData: MenuItemProps[], moduleId: string, newItems: MenuItemProps[]) {
    return menuItemsData.map((item) => {
      if (item.idMenu === moduleId) {
        item = this.createMenuItem(item, newItems);
      } else if (item.menuList?.length) item.menuList = this.insertNewItemsOnData(item.menuList, moduleId, newItems);

      return item;
    });
  }

  private formatValuesToInsert(currentPath: SelectOptions, values: MenuItemProps) {
    let body = { ...values };

    const parent = this.getModule(currentPath.value);
    const parentMenuList = parent?.menuList?.map(item => ({...item, stRegistro: 1, menuList: []})) || [];

    body = {
      ...parent,
      stRegistro: parent.stRegistro === null ? 1 : 0,
      menuList: [...parentMenuList, values],
    };

    return body;
  }
}

export default ConfigureMenuController;
