import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { MainStateType } from "root-states";
import AppLayoutActions from "root-states/actions/app-layout-actions";
import { AppLayoutStateType } from "root-states/reducers/app-layout-reducer";
import { DispatchAction } from "root-states/root-dispatcher";

/**
 * Hook customizado para utilizar estado do layout dos módules
 * 
 * @author Marcos Davi <marcos.davi@bubo.tech>
 */
function useAppLayout(): AppLayoutManager {
  const actions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const state = useSelector<MainStateType, AppLayoutStateType>((state) => state.appLayoutReducer);

  return {
    actions,
    state
  };
}

type AppLayoutManager = {
  actions: AppLayoutActions;
  state: AppLayoutStateType;
}

export default useAppLayout;