import Swal from "./swal";

function SuccessSwal(message: string = 'Operação feita com sucess', title = 'Sucesso') {
  Swal({
    showConfirmButton: true,
    title: title,
    text: message,
    icon: 'success'
  });
}

export default SuccessSwal;